import React from "react";
import styled from "styled-components";
import { Box, Image, Lead, Container } from "../styles/Styles";
import theme from "../styles/theme";
import icon1 from "../img/icon-hex-globe-loc-1.svg"
import icon2 from "../img/icon-hex-globe-loc-2.svg"
import icon3 from "../img/icon-hex-globe-loc-3.svg"
import icon4 from "../img/icon-hex-globe-loc-4.svg"


const timeline = [
  {
    title: "2020年1月",
    sub: "(インド)",
    icon: icon1,
    pos: "left"
  },
  {
    title: "2020年3月",
    sub: "(+1 地域)",
    icon: icon2,
    pos: "center"
  },
  {
    title: "2020年5月",
    sub: "(+1 地域)",
    icon: icon3,
    pos: "center"
  },
  {
    title: "2020年7月",
    sub: "(+1 地域)",
    icon: icon4,
    pos: "right"
  }
];

const ModelsTimeline = () => {

  return (
    <Container>
      <Box display='flex' justifyContent='center' flexWrap='wrap'>
        {timeline.map((item, i) => {
          return (
            <Item pos={item.pos} width={[1 / 2, 1 / 4]} px={[3]} key={item.title} className={item.pos}>
              <Box mb={[2, 2, 5]}>
                <Image width={120} src={item.icon} alt=""/>
              </Box>
              <Box color={i===0 ? theme.color.primary : null}>
                <Lead fontWeight={800} mb={1}>{item.title}</Lead>
                <Box mb={3} dangerouslySetInnerHTML={{ __html: item.sub }}/>
              </Box>
            </Item>
          );
        })}
      </Box>
    </Container>
  );
};

export default ModelsTimeline;

const Item = styled(Box)`
  position: relative;
  margin-bottom: 3rem;
  @media only screen and (min-width: 1024px) {
    &:before {
      content: "";
      position: absolute;
      top: 160px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 8px;
      background-color: #e7e7e7;
    }
    &:after {
      content: "";
      position: absolute;
      top: 148px;
      left: 50%;
      transform: translateX(-50%);
      width: 6px;
      height: 13px;
      background-color: #e7e7e7;
    }
    &.left {
      &:before {
        left: auto;
        right: -1px;
        transform: unset;
        width: calc(50% + 4px);
      }
    }
    &.right {
      &:before {
        left: -1px;
        transform: unset;
        width: calc(50% + 4px);
      }
    }
  }
`;