import React from "react";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Seo";
import LazyLoad from "react-lazyload";
import Modali, {useModali} from "modali";
import styled from "styled-components";
import {Box, Button, Container, Lead, T2, Image, T3} from "../styles/Styles";
import theme from "../styles/theme";
import Clients from "../components/Homepage/Clients";
import IndustriesCarouselCbam from "../components/IndustriesCarouselCbam";
import CbamTimeline from "../components/CbamTimeline";
import ComparingCbam from "../components/ComparingCbam";
import thumb1 from "../img/cbam-thumb-1.png";
import arrowLeft from "../img/arrow-fancy-left.svg";
import arrowRight from "../img/arrow-fancy-right.svg";
import loc from "../img/icon-hex-loc.svg";
import width from "../img/icon-hex-width.svg";
import search from "../img/icon-hex-search.svg";
import sett from "../img/icon-hex-settings.svg";
import edit from "../img/icon-hex-edit.svg";
import globe from "../img/icon-hex-globe-2.svg";
import sens from "../img/icon-hex-sensor.svg";
import refr from "../img/icon-hex-refresh.svg";
import pin from "../img/icon-hex-pin.svg";
import sun from "../img/icon-hex-cloud-sun.svg";
import quoteAng from "../img/quote-angle.svg";
import milrad from "../img/shawn-milrad-hex-color.png";
import embry from "../img/embry-riddle-aeronautical-university-logo.svg";
import works1 from "../img/cbam-works-1.png";
import works2 from "../img/cbam-works-2.png";
import google from "../img/google-cloud-cbam-india.png";

const hero = "https://cms2.climacell.co/wp-content/uploads/2020/01/cbam-hero.gif";
const thumb2 = "https://cms2.climacell.co/wp-content/uploads/2020/01/cbam-thumb-2.mp4";
const works3 = "https://cms2.climacell.co/wp-content/uploads/2020/01/cbam-works-3.gif";

const features1 = [
    {icon: loc, text: "ロケーション"},
    {icon: search, text: "解像度"},
    {icon: width, text: "適応範囲"},
    {icon: sett, text: "パラメーター"}
];

const features2 = [
    {icon: search, text: "超高解像度"},
    {icon: globe, text: "世界中で利用可"},
    {icon: edit, text: "ニーズによりカスタマイズ"},
    {icon: sens, text: "何百ものバーチャルセンサーから収集"},
    {icon: refr, text: "超高速なリフレッシュタイム"}
];

const features3 = [
    {icon: pin, text: "関心のあるエリアを特定", img: works1},
    {icon: sens, text: "何百ものバーチャルセンサー観測を融合", img: works2},
    {icon: sun, text: "ハイパーローカルな微調整された気象予報を入手", img: works3}

];

export default ({location}) => {
    const [modal, toggleModal] = useModali({
        animated: true,
        closeButton: false
    });

    return (
        <Layout>
            <SEO
                title="CBAM | tomorrow.io"
                pathname={location.pathname}
            />

            <Container py={5}>
                <Box display="flex" alignItems="center" flexWrap="wrap">
                    <Box width={[1, 1 / 2]} pr={[0, 4]}>
                        <T2 as="h1" mb={3}>CBAM－世界でもっとも<nobr>高解像度な</nobr>気象予報モデル</T2>
                        <Lead mb={4}>
                            希望する解像度、適応範囲、パラメーターにカスタマイズ。世界中どの地域においても細かく調整
                        </Lead>
                        <Button
                            as="a"
                            display={["block", "inline-block"]}
                            mb={3}
                            id="cbam-link-form-1"
                            href="https://www.tomorrow.io/get-a-demo-of-cbam/"
                        >
                            オーダーメード予報を入手
                        </Button>{" "}
                        <Button
                            width={[1, "auto"]}
                            display={["block", "inline-block"]}
                            mb={3}
                            outline={1}
                            onClick={toggleModal}
                        >
                            ビデオを観る
                        </Button>
                        <Modali.Modal {...modal}>
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe
                                    className="embed-responsive-item"
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/vzYGAowbqfc?rel=0"
                                    frameBorder="0"
                                    allowFullScreen
                                    title="CBAM"
                                />
                            </div>
                        </Modali.Modal>
                    </Box>
                    <Box width={[1, 1 / 2]}>
                        <Image src={hero} alt="CBAM"/>
                    </Box>
                </Box>
            </Container>

            <Container py={5}>
                <Clients/>
            </Container>

            <Box py={5} bg={theme.color.light}>
                <Container>
                    <T3 width={[1, 3 / 5]} mb={3} textAlign="center" mx="auto">
                        もっとも正確なオーダーメード予報<br/>
                        誰にでも、どこででも
                    </T3>
                    <Lead width={[1, 3 / 5]} textAlign="center" mx="auto" mb={5}>
                        特定の地域における、微妙な天候を完全に把握できないグローバルモデルと<nobr>違い、</nobr>CBAMには次のような特徴があります：
                    </Lead>

                    <Box display="flex" flexWrap="wrap" mb={[4, 5]}>
                        <BoxWithArrow right width={[1, 1 / 2]}>
                            <LazyLoad once height={600}>
                                <Image src={thumb1} boxShadow="0 3px 10px rgba(0,0,0,.05)" borderRadius={6} alt=""/>
                            </LazyLoad>
                        </BoxWithArrow>
                        <Box width={[1, 1 / 2]}>
                            <Lead pl={[0, 5]} textAlign={["center", "left"]} pt={[3, 6]} width={[1, "70%"]}>
                                世界中の何百万ものバーチャル<nobr>センサー</nobr>から情報収集
                            </Lead>
                        </Box>
                    </Box>

                    <Box display="flex" flexWrap="wrap">
                        <Box width={[1, 1 / 2]}>
                            <Lead textAlign={["center", "right"]} pt={[3, 6]} ml={[0, "auto"]} pr={[0, 5]}
                                  width={[1, "70%"]}>
                                グローバルにカスタマイズができ、<br/>地域毎に調整が可能
                            </Lead>
                        </Box>
                        <BoxWithArrow width={[1, 1 / 2]}>
                            <LazyLoad once height={600}>
                                <Video
                                    controls={false}
                                    muted
                                    playsInline
                                    autoPlay
                                    loop
                                >
                                    <source src={thumb2} type="video/mp4"/>
                                    Your browser does not support the video tag
                                </Video>
                            </LazyLoad>
                        </BoxWithArrow>
                    </Box>

                </Container>
            </Box>

            <Box py={5} textAlign="center">
                <Container>
                    <T3 mb={5}>必要とするものを選択：</T3>
                    <Box width={[1, "80%"]} mx="auto" display="flex" justifyContent="center" flexWrap="wrap">
                        {features1.map((item, i) => (
                            <Box width={[1 / 2, 1 / 4]} mb={5} textAlign="center" key={i}>
                                <Image mb={2} src={item.icon} alt=""/>
                                <Box>{item.text}</Box>
                            </Box>
                        ))}
                    </Box>
                    <Lead mb={5} width={[1, "80%"]} mx="auto">
                        もっとも正確な予報を入手して、特定のニーズに応じてオーダーメード
                    </Lead>
                    <Button
                        as="a"
                        display={["block", "inline-block"]}
                        mb={3}
                        href="https://www.tomorrow.io/get-a-demo-of-cbam/"
                        id="cbam-link-form-2"
                    >
                        CBAMのデモを予約
                    </Button>
                </Container>
            </Box>

            <Box pt={5}>
                <Container>
                    <T3 mb={5} textAlign="center">
                        CBAMの導入先は？
                    </T3>
                    <LazyLoad height={600} offset={600} once>
                        <div className="mx-auto mb-5" style={{maxWidth: 1000}}>
                            <IndustriesCarouselCbam/>
                        </div>
                    </LazyLoad>
                </Container>
            </Box>

            <Box py={5} textAlign="center">
                <Container>
                    <T3 mb={4}>❝未対応に対応❞</T3>
                    <LazyLoad height={400} once>
                        <Image
                            onClick={toggleModal}
                            mb={4}
                            width={600}
                            src={google}
                            alt=""
                            borderRadius={8}
                            boxShadow="0 1px 5px rgba(0,0,0,.1)"
                            style={{cursor: 'pointer'}}
                        />
                    </LazyLoad>
                    <br/>
                    <Button display="inline-block" as="a"
                            href="https://console.cloud.google.com/marketplace/details/climacell/cbam" mb={3}>
                        さっそく試す
                    </Button>
                    <Lead mb={5} width={[1, "55%"]} mx="auto">
                        私たちは、生活を脅かすような気象データは、すべての人に情報が行き届くべきであると考えます。tomorrow.ioは、Googleクラウドと連携し、まずはインドからCBAMの無料版を提供します。<br/>世界で次に展開されるのは、どの国か気になりますか？
                    </Lead>
                    <LazyLoad height={600} once>
                        <CbamTimeline/>
                    </LazyLoad>
                    <Lead mb={4}>次にCBAMが展開すべき地域はどこでしょう？</Lead>
                    <Button as="a" href="https://Tomorrow.io/cbam/vote/">推薦する</Button>
                </Container>
            </Box>

            <Box pt={5} textAlign="center" bg={theme.color.light}>
                <Container>
                    <T3 mb={5}>CBAMを選ぶ理由は？</T3>
                    <Box width={[1, "80%"]} mx="auto" display="flex" justifyContent="center" flexWrap="wrap">
                        {features2.map((item, i) => (
                            <Box width={[1 / 2, 1 / 3]} mb={5} textAlign="center" key={i}>
                                <Image mb={2} src={item.icon} alt=""/>
                                <Box px={3}>{item.text}</Box>
                            </Box>
                        ))}
                    </Box>
                </Container>
            </Box>

            <Box pt={6} pb={4}>
                <Container>
                    <Quote width={[1, 2 / 3]}>
                        <Image src={embry} alt="" position="absolute" top={-45} left={0}/>
                        <p>tomorrow.ioは、現在画期的な短期気象予報システム（NowCastならびにCBAM）を運用している。実証試験を通して、大気圏の変数である降水量（NowCast）と地球全体の気温、雲量、風速（CBAM）を的確に予報することができており、ふたつのプロダクトは実に印象深く、ユニークな可能性を示している。NowCastならびにCBAMは、一般的に利用されている気象予報モデルデータを、特に短期間の予報において、かなりの精度と正確さにおいて上回っている。
                        </p>
                        <Box display="flex" alignItems="center" fontSize={2}>
                            <Image width={64} mr={3} src={milrad} alt=""/>
                            <span><strong>ショーン・ミルラッド博士、</strong><br/>  准教授、気象学</span>
                        </Box>
                        <Circle/>
                    </Quote>
                </Container>
                <Box pt={6} px={2} textAlign="center">
                    <Button
                        as="a"
                        display={["block", "inline-block"]}
                        mb={3}
                        id="cbam-link-form-3"
                        href="https://Tomorrow.io/request-validation/"
                    >
                        すべての検証報告書を請求
                    </Button>
                </Box>
            </Box>

            <Box pt={5}>
                <Container>
                    <T3 mb={5} textAlign="center">どのような仕組みでしょう？</T3>
                    <Box width={[1, "80%"]} mx="auto" display="flex" alignItems="flex-start"
                         justifyContent="space-between"
                         flexWrap="wrap">
                        {features3.map((item, i) => (
                            <NumberBox number={i + 1} width={[1, 1, "30%"]} mb={5} key={i}>
                                <Box display={["block", "flex"]} textAlign={["center", "left"]} alignItems="center"
                                     justifyContent="center" mb={3}>
                                    <Image mr={3} mb={2} src={item.icon} alt=""/>
                                    <Box mb={2}>{item.text}</Box>
                                </Box>
                                <Box textAlign="center">
                                    <LazyLoad height={300} once><Image src={item.img} maxHeight={170}
                                                                       alt=""/></LazyLoad>
                                    {i === 2 &&
                                    <small style={{color: "#777"}}>*解像度81メートルの突風</small>}
                                </Box>
                            </NumberBox>
                        ))}
                    </Box>
                </Container>
            </Box>

            <Box py={5} bg={theme.color.light}>
                <Container>
                    <T3 mb={5} textAlign="center">CBAMと他のモデル (HRRR, JMA, GRAF)の比較：</T3>
                    <ComparingCbam/>
                    <Box textAlign="center" pt={5}>
                        <Button
                            as="a"
                            display={["block", "inline-block"]}
                            mb={3}
                            id="cbam-link-form-4"
                            href="https://www.tomorrow.io/get-a-demo-of-cbam/"
                        >
                            デモを予約
                        </Button>
                    </Box>
                </Container>
            </Box>

        </Layout>
    );
};


const Video = styled.video`
  max-width: 100%;
  box-shadow: 0 3px 10px rgba(0,0,0,.05);
  border-radius: 6px;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: white;
`;

const BoxWithArrow = styled(Box)`
  position: relative;
  &:after {
  @media only screen and (min-width: 768px) {
      content: '';
      position: absolute;
      right: ${props => props.right ? "-90px" : "auto"};
      left: ${props => props.right ? "auto" : "-90px"};
      top: 60px;
      background: url(${props => props.right ? arrowRight : arrowLeft}) no-repeat top center / contain;
      width: 70px;
      height: 65px;
    }
  }
`;

const Quote = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
  border-radius: 3px;
  padding: 2rem 3rem;
  background-color: white;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    bottom: -3px;
    right: -3px;
    background: url(${quoteAng}) no-repeat center;
    width: 34px;
    height: 32px;
  }
  &:after {
    @media only screen and (min-width: 768px) {
      content: '';
      position: absolute;
      z-index: -1;
      bottom: -10px;
      right: -10px;
      width: 100%;
      height: 100%;
      border: 1px solid black;
      border-radius: 3px;
      background-color: white;
    }
  }
`;

const Circle = styled.div`
  @media only screen and (min-width: 768px) {
    width: 460px;
    height: 460px;
    border-radius: 50%;
    background-color: ${theme.color.primary};
    position: absolute;
    z-index: -2;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
`;

const NumberBox = styled(Box)`
  position: relative;
  &:after {
    content: '${props => props.number}';
    position: absolute;
    z-index: -1;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    color: #EFF9FF;
    font-weight: 900;
    font-size: 200px;
    line-height: 1;
    @media only screen and (min-width: 1024px) {
      left: 40px;
      transform: none;
    }
  }
  
`;