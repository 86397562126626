import React, { useEffect, useState } from "react";
import EmblaCarouselReact from "embla-carousel-react";
import useIntervalWithHooks from "./Utils/useIntervalWithHooks";
import aviationIcon from "../img/icon-aviation-hex.svg";
import constructionIcon from "../img/icon-construction-hex.svg";
import sportIcon from "../img/icon-sport-hex.svg";
import energyIcon from "../img/icon-energy-hex.svg";
import dronesIcon from "../img/icon-drones-hex.svg";
import ondemandIcon from "../img/icon-ondemand-hex.svg";
import nathan from "../img/nathan-ruff-hex.png";
import zach from "../img/zach-clarke-hex.png";
import ari from "../img/ari-luks-hex.png";
import ian from "../img/ian-deason-hex.png";
import jason from "../img/jason-stone-hex.png";
import lisa from "../img/lisa-lambert-hex.png";
import via from "../img/via-logo-color.png";
import jet from "../img/jetblue-logo-color.png";
import castle from "../img/white-castle-logo.png";
import patriots from "../img/patriots-logo-color.png";
import grid from "../img/logo-national-grid.png";
import uas from "../img/uasidekick-logo.png";
import avia from "../img/thumb-aviation.png";
import constr from "../img/thumb-construction.png";
import outdoor from "../img/thumb-outdoor.png";
import energy from "../img/thumb-energy.png";
import drones from "../img/thumb-drones.png";
import deliver from "../img/thumb-deliveries.png";
import { Image } from "../styles/Styles";

const industries = [
  {
    name: "航空",
    icon: aviationIcon,
    benefits: ["安全性の向上", "オペレーションの最適化", "フライト遅延の減少"],
    img: avia,
    quote: {
      text: "このツールは、日々空港リーダーたちが直面している決断しなければいけないプレッシャーをよく理解してデザインされています。",
      person: "イアン・デーソン氏、SVP Customer Experience、JetBlue",
      portrait: ian,
      logo: jet,
    },
  },
  {
    name: "建設",
    icon: constructionIcon,
    img: constr,
    benefits: [
      "オペレーションの効率化向上",
      "スタッフの安全性向上",
      "雷の予測",
    ],
    quote: {
      text:
        "私たちの大きなチャレンジは、どの気象予報でも天気は良好と認識し、スタッフを送りだした後に、突然どこからともなく雷を伴った嵐がやってくるような時。今では、アラートシステムのお陰で、事前に自動で知ることができるようになりました。",
      person:
        "ザック・クラーク氏、Director of Residential Operations、White Castle Roofing",
      portrait: zach,
      logo: castle,
    },
  },
  {
    name: "スポーツ＆アウトドア",
    icon: sportIcon,
    benefits: [
      "不必要な遅延や中止を最小限に",
      "設備損傷を減らす",
      "開催地を究極な天候状況に備える",
    ],
    img: outdoor,
    quote: {
      text:
        "もし雷が土地に落ちる可能性がある場合、皆を避難場所へ誘導するためにも、その危険性を事前に把握する必要があります。tomorrow.ioのツールはいつそのような状況が発生するのかと、過ぎ去るのかを教えてくれます。",
      person:
        "ジェイソン・ストーン氏、Senior Director of Operations、Kraft Sports and Entertainment",
      portrait: jason,
      logo: patriots,
    },
  },
  {
    name: "エネルギー",
    icon: energyIcon,
    benefits: [
      "資産活用のためのリアルタイムデータを入手",
      "分単位で更新される降水量と火災インデックス",
      "資産に特化した気象インサイト",
    ],
    img: energy,
    quote: {
      text:
        "天候は私たちにとって危機管理、ならびに嵐への対応や回復の為に大変重要であり、エリアにおける気象予報の正確な情報を手に入れることで、対策の向上とコスト削減が可能になります。",
      person: "リサ・ランバート氏、CTO 兼 Senior Vice President、National Grid",
      portrait: lisa,
      logo: grid,
    },
  },
  {
    name: "ドローン",
    icon: dronesIcon,
    benefits: [
      "安全性の確保",
      "ルート計画の向上",
      "オペレーションの効率性向上",
    ],
    img: drones,
    quote: {
      text:
        "UASidekickの目標は、操縦士の安全を確保すること。tomorrow.ioのMicro Weatherソリューションのような、次世代ツールを提供することはUAS産業を前進させるために欠かせないことです。",
      person: "ナタン・ラフ氏、 UASidekick CEO",
      portrait: nathan,
      logo: uas,
    },
  },
  {
    name: "オンデマンド",
    icon: ondemandIcon,
    benefits: [
      "需要と共有の変化に備える",
      "ETAの正確性向上",
      "資産の保護",
    ],
    img: deliver,
    quote: {
      text:
        "tomorrow.ioの予報は、正確で、リアルタイムの実用的な気づきを提供してくれます。tomorrow.ioのプロダクトに大変感心し、私たちのオペレーション意思決定や、顧客に質の高いサービスを提供するのに役立っています。",
      person: "アリ・ラックス氏、Operations, Via",
      portrait: ari,
      logo: via,
    },
  },
];

export default () => {


  const [selected, setSelected] = useState(0);
  const [delay, setDelay] = useState(10000);
  const [embla, setEmbla] = useState(null);

  useEffect(() => {
    if (embla) {
      embla.on("select", () => {
        setSelected(embla.selectedScrollSnap);
      });
    }
  }, [embla]);

  const onSelect = i => {
    embla.scrollTo(i);
    setDelay(null);
  };

  useIntervalWithHooks(() => {
    embla.scrollNext();
  }, delay);

  return (
    <>
      <div className="d-flex flex-wrap justify-content-center fs-lg mb-3 mb-lg-4">
        {industries.map((item, i) => (
          <button
            onClick={() => onSelect(i)}
            key={i}
            className={`btn btn-link fs-lg px-1 px-md-2 fw-800 ${
              selected === i ? " text-black" : "text-muted"
            }`}
          >
            {item.name}
          </button>
        ))}
      </div>
      <EmblaCarouselReact emblaRef={setEmbla} options={{ loop: false }}>
        <div className="d-flex mb-4">
          {industries.map((industry, index) => (
            <div key={industry.name} style={{ flex: "0 0 100%" }}>
              <div className="px-4 py-3">
                <div className="row justify-content-center shadow-md rounded-lg overflow-hidden bg-white">
                  <div className="col-lg-6 pt-3 pb-4 pb-lg-2 px-4">
                    <h3 className="h-4 d-flex align-items-center">
                      <img
                        width={35}
                        src={industry.icon}
                        className="mr-3"
                        alt=""
                      />
                      {industry.name}
                    </h3>
                    <ul className="py-3 c-list">
                      {industry.benefits &&
                        industry.benefits.length > 0 &&
                        industry.benefits.map((benefit, i) => (
                          <li key={i} className="c-list-item-circle">
                            {benefit}
                          </li>
                        ))}
                    </ul>
                    {industry.quote && (
                      <div>
                        <p className="mb-3 fw-800 text-muted">
                          {industry.quote.text}
                        </p>
                        <div className="media align-items-start">
                          {industry.quote.portrait && (
                            <img
                              height={80}
                              src={industry.quote.portrait}
                              className="mr-3"
                              alt={industry.quote.person}
                            />
                          )}
                          <div className="media-body">
                            <div className="mb-2">{industry.quote.person}</div>
                            <img height={28} src={industry.quote.logo} alt="" />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6 px-0 d-none d-lg-block bg-white">
                    <Image src={industry.img} alt={industry.name}/>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </EmblaCarouselReact>
    </>
  );
};
