import React from "react";
import styled from "styled-components";
import LazyLoad from "react-lazyload";
import { Box, Image, T5 } from "../styles/Styles";
import img1 from "../img/cbam-comparing-1.png";
import img2 from "../img/cbam-comparing-2.png";
import arrow from "../img/arrow-both.svg";

const cbam = {
  title: "CBAM",
  img: img2,
  items: [
    "いかなる解像度も、<br/>ニーズによりカスタマイズ",
    "15分毎に更新",
    "微細スケールでの地形解像",
    "MicroWeather API/tomorro.io/Google Cloud一般公開データセット"
  ]
};
const others =
  {
    title: "他モデル",
    img: img1,
    items: [
      "最大で3km解像度",
      "最高で一時間毎に更新",
      "なし",
      "HRRR / JMA - 無料。GRAF - 未対応"
    ]
  };

const labels = ["性能", "頻度", "領域調整", "可用性"];

const ComparingCbam = () => {

  return (
    <>
      {/*Desktop*/}
      <Box display={["none", "none", "flex"]} flexWrap="wrap" justifyContent='center' width='75%' mx="auto">
        <Box width='37%' position="relative" zIndex={1} textAlign="right">
          <T5 mb={4}>{cbam.title}</T5>
          <LazyLoad once height={250}><Image src={cbam.img} mb={4} alt=""/></LazyLoad>
        </Box>
        <VS width='26%' textAlign="center" pt={300}/>
        <Box width='37%' position="relative" zIndex={1}>
          <T5 mb={4}>{others.title}</T5>
          <LazyLoad once height={250}><Image src={others.img} mb={4} alt=""/></LazyLoad>
        </Box>
        <Box width='37%' textAlign="right">
          {cbam.items.map((item, i) => (
            <Box mb={4} key={i} dangerouslySetInnerHTML={{__html: item}}/>
          ))}
        </Box>
        <Box width='26%' textAlign="center">
          {labels.map((label, i) => (
            <ArrowBox mb={4} key={i} textAlign="center" fontWeight={800}>{label}</ArrowBox>
          ))}
        </Box>
        <Box width='37%'>
          {others.items.map((item, i) => (
            <Box mb={4} key={i} dangerouslySetInnerHTML={{__html: item}}/>
          ))}
        </Box>
      </Box>

      {/*Mobile*/}
      <Box display={["block", "block", "none"]} width={[1, "60%"]} mx="auto" mb={4}>
        <Box bg="white" p={4} borderRadius={8}>
          <T5 mb={4} textAlign="center">{cbam.title}</T5>
          <LazyLoad once height={250}><Image src={cbam.img} mb={4} alt=""/></LazyLoad>
          <Box display='flex' flexWrap="wrap">
            <Box width='40%' pr={2}>
              {labels.map((label, i) => (
                <Box key={i} minHeight={70} fontStyle="italic">{label}</Box>
              ))}
            </Box>
            <Box width='60%'>
              {cbam.items.map((item, i) => (
                <Box minHeight={70} key={i}>{item}</Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box display={["block", "block", "none"]} width={[1, "60%"]} mx="auto">
        <Box bg="white" p={4} borderRadius={8}>
          <T5 mb={4} textAlign="center">{others.title}</T5>
          <LazyLoad once height={250}><Image src={others.img} mb={4} alt=""/></LazyLoad>
          <Box display='flex' flexWrap="wrap">
            <Box width='40%' pr={2}>
              {labels.map((label, i) => (
                <Box key={i} minHeight={70} fontStyle="italic">{label}</Box>
              ))}
            </Box>
            <Box width='60%'>
              {others.items.map((item, i) => (
                <Box minHeight={70} key={i}>{item}</Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ComparingCbam;


const ArrowBox = styled(Box)`
  position: relative;
  bottom: 15px;
  &:after {
    content: "";
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    background: url(${arrow}) no-repeat center / cover;
    width: 75%;
    height: 5px;
  }
`;

const VS = styled(Box)`
  position: relative;
  &:after {
    content: "VS";
    position: absolute;
    z-index: 0;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 200px;
    color: #e1e1e1;
    font-weight: 900;
  }
`;